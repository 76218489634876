@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css";

@font-face {
  font-family: baloo;
  src: url(assets/BalooBhaina2-Regular.ttf);
}




@import url("../node_modules/bootstrap/dist/css/bootstrap.min.css");


html,
body {
  height: 100% !important;
  width: 100% !important;
}

* {}

.swapflex {
  display: flex;
  flex-direction: column;
  /* Default value for screens smaller than 500px */
}

@media (min-width: 500px) {
  .swapflex {
    flex-direction: row;
    /* Applied for screens wider than 500px */
  }
}

.white-space-pre-line {
  white-space: pre-line;
}

body {
  margin: 0;
  font-family: baloo, "Helvetica Neue", sans-serif;
}

::-webkit-scrollbar {
  width: 13px;
}

::-webkit-scrollbar-button {
  width: 100px;
  height: 50px;
}

::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border: 0px none #ffffff;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}

::-webkit-scrollbar-thumb:active {
  background: #797272;
}

::-webkit-scrollbar-track {
  background: #666666;
  border: 0px none #ffffff;
  border-radius: 35px;
}

::-webkit-scrollbar-track:hover {
  background: #504e4e;
}

::-webkit-scrollbar-track:active {
  background: #877d7d;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

.vertical-divider {
  width: 1px;
  background-color: silver;
}

ngb-modal-backdrop {
  z-index: 20 !important;
}

.even-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.flex-rows {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  max-width: 100% !important;
  overflow: auto !important;
  max-height: 100% !important;
  width: 100%;
  align-items: flex-start;
  position: relative;
}

.controlledText {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-align: center;
  min-width: 100px;
  max-width: 250px;
  width: auto;
}

@media only screen and (max-width: 768px) {

  /* For mobile phones: */
  [class="flex-columns"] {
    width: 100% !important;
  }
}

.scrollable-column {
  overflow-x: hidden;
  overflow-y: auto;
  white-space: nowrap;
  max-height: calc(100% - 20px);
}

.flex-columns {
  flex-shrink: 1;
  max-width: 100%;
  margin: 5px;
  top: 0;
  padding: 12px 12px 12px 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.flex-columns>button {}

.cal-month-view .cal-day-badge {
  display: none !important;
}

.flex {
  display: flex;
}

.row {
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit {
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}

.modal-backdrop+.modal-backdrop {
  z-index: 1040;
}

.modal+.modal {
  z-index: 1050;
}

.backdrop {
  background-color: black;
  opacity: 0.6 !important;
  z-index: 1055 !important;
}

.second-modal {
  z-index: 1060 !important;
}

.edit-row {}

.edit-desc {
  white-space: nowrap;
  overflow: hidden;
  margin-left: 5px;
  font-size: x-small;
  font-style: italic;
}

.edit-desc-hidden {
  display: none;
}

.edit-desc-larger {
  white-space: nowrap;
  overflow: hidden;
  margin-left: 5px;
  font-size: normal;
  font-style: italic;
}

.divider {
  border: none;
  height: 1px;
  background-color: #666;
  /* Modern Browsers */
  margin-top: 8px;
  margin-bottom: 8px;
  width: 100%;
}

.highlighted {
  background-color: aqua;
}

.button-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: stretch;
  align-content: stretch;
  width: 100% !important;
}

.button-row.scroll {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: stretch;
  width: 100% !important;
  overflow-x: auto;
  overflow-y: hidden !important;
}

.full-width-button {
  width: 100%;
}

.color-scheme-dark .mat-select-panel {
  background: rgba(0, 0, 0, 1) !important;
}

.paginator {
  margin-top: 5px;
}

.artist-table-name {
  max-width: 150px !important;
}

.artist-table-other {}

text-area {
  white-space: none;
}

th,
td {
  padding: 3px !important;
  vertical-align: top;
  font-weight: normal;
}

.custom-day {
  text-align: center;
  padding: 0.185rem 0.25rem;
  display: inline-block;
  height: 2rem;
  width: 2rem;
}

.custom-day.focused {
  background-color: #e6e6e6;
}

.custom-day.range,
.custom-day:hover {
  background-color: rgb(2, 117, 216);
  color: white;
}

.custom-day.faded {
  background-color: rgba(2, 117, 216, 0.5);
}

.fix .ngx-json-viewer[_ngcontent-c14] {
  overflow: auto !important;
}

::ng-deep .ngx-json-viewer {
  overflow: auto !important;
}

.fix>>>.ngx-json-viewer {
  overflow: auto !important;
}


/* buttons */

.datchev {
  width: 100px;
  height: 45px;
  border-radius: 10px;
  background-color: orange;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid black;
}

.datchev>i {
  font-size: xx-large;
}

.datchev:disabled {
  opacity: 50%;
  border: 1px solid black;
}

.label {
  font-size: small;
  font-style: italic;
}

.option-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 2px 10px;
  outline: 0px;
  border: 1px outset gray;
  border-radius: 5px;
  position: relative;
  box-shadow: 1px 1px 3px 0px #000000ff;
  -webkit-box-shadow: 1px 1px 3px 0px #000000ff;
}

.option-button>i {
  margin: 0px 5px;
}

.option-button>div {
  margin: 0px 5px;
}

.option-button:hover {
  box-shadow: 1px 1px 3px 1px #000000ff;
  -webkit-box-shadow: 1px 1px 3px 1px #000000ff;
}

.option-button:active {
  background-color: rgba(0, 0, 0, 0.589) !important;
}

.label-input-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.label-input-row>label {
  margin-right: 5px;
  font-style: italic;
}

.label-input-row>input {
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
}

.plain-input {
  background: transparent !important;
  border-bottom: 1px solid white;
}

.close-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid black;
  background-color: #FF2400;
  color: white;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 4px;
  text-align: center;
}

.small-button {
  border-radius: 5px;
  padding: 4px;
  margin: 4px;
}

.small-button:hover {
  transform: scale(1.1);
  opacity: 0.8;
}

.reactive-button-title {
  flex-grow: 1;
  border: none;
  outline: none;
  padding-top: 7px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.reactive-button-title:disabled {
  opacity: 50%;
}

.reactive-button-title:hover {
  padding-top: 6px;
  padding-bottom: 4px;
  padding-left: 9px;
  padding-right: 9px;
  cursor: pointer;
  border: 1px solid black;
  box-shadow: 0px 0px 5px 0px black;
}


/* Tables */

.generic-table {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: black;
  flex-grow: 1;
  overflow-x: auto;
  overflow-y: scroll;
}

.generic-table-header {
  width: 100%;
  padding-left: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.generic-table-header-item {
  position: relative;
  text-decoration: underline;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.generic-table-header-item.short {
  width: 150px;
}

.generic-table-header-item.long {
  width: 300px;
}

.generic-cols-4>* {
  width: 25%;
}

.generic-cols-5>* {
  width: 20%;
}

.generic-cols-6>* {
  width: calc(100% / 6);
}

.generic-table-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 5px;
  padding: 4px 10px 2px 10px;
  border-radius: 5px;
  background-color: #f5f5f5;
  width: 100%;
  border: 1px solid darkgray;
}

.generic-table-row.grow>* {
  flex-grow: 1;
  flex-shrink: 1;
}

.generic-table-header.grow>* {
  flex-grow: 1;
  flex-shrink: 1;
}

.generic-table-row:hover {
  background-color: #e5e5e5;
  cursor: pointer;
  border: 1PX solid green;
}

.generic-table-row:active {
  background-color: #858282;
  cursor: pointer;
  transform: scale(0.99);
}

.generic-table-item {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.generic-table-icons {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.generic-table-item.short {
  width: 150px;
}

.generic-table-item.long {
  width: 300px;
}

.generic-icon {
  height: 100%;
  max-height: 35px;
  max-width: 35px;
  aspect-ratio: 1/1;
  border: 1px solid black;
  border-radius: 50%;
  box-shadow: 1px 1px 3px 0px #000000ff;
  -webkit-box-shadow: 1px 1px 3px 0px #000000ff;
  padding: 4px;
  margin: 0px 5px;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.generic-icon:hover {
  box-shadow: 1px 1px 3px 1px #000000ff;
  -webkit-box-shadow: 1px 1px 3px 1px #000000ff;
  background-color: #e5e5e5;
}

.loading-backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1050;
  overflow: hidden;
}

.loading-window {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex !important;
  align-items: center;
  justify-content: center;
  z-index: 1055;
  overflow: hidden;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

.loading-modal-dialog {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1060;
  overflow: hidden;
  margin: 0px !important;
}

.loading-modal-dialog>* {
  background-color: transparent !important;
  border-color: transparent !important;
  width: 100%;
  height: 100%;
}


/* Modal */

.generic-modal-footer,
.generic-modal-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
}

.generic-modal-header {
  padding: 15px;
}

.generic-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 0px 0px 5px 5px;
  color: black;
  flex-grow: 1;
  overflow: auto;
  max-height: 100%;
}

.generic-modal-content.start {
  align-items: flex-start !important;
}

.generic-modal-content-row {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: 5px;
  width: 100%;
}

.generic-modal-content-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 4px 10px 2px 10px;
  border-radius: 5px;
  background-color: #d6d6d6;
  width: 100%;
  border: 1px solid darkgray;
  min-height: 50px;
  margin: 5px;
  max-width: 50%;
}

.generic-modal-content-box-title {
  font-weight: bold;
  font-size: large;
  margin-bottom: 5px;
}

@media (max-width: 768px) {
  app-map {
    display: none;
  }

  .bar-buttons {
    position: relative !important;
    top: 0px;
    left: 0px;
  }
}

app-map {
  box-shadow: 0 0 15px 5px rgb(90, 90, 90);
  border-radius: 10px;
  overflow: hidden;
  position: absolute;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  top: 0;
  left: 0;
  margin: 5px;
}

.bar-buttons {

  display: flex;
  flex-direction: column;
  z-index: 1;
  width: 270px;

}

.modal-content {
  min-height: 750px;
}