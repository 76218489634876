.my-table-sort-arrow:hover {
  background-color: red;
}

.my-table-sort-arrow {
  width: 40px;
  height: 40px;
  background-color: #535353;
  border-color: 1px solid white;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}

.my-table-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 15px;
}

.my-table-sort-arrow > i {
  font-size: xx-large;
}

.my-table-p {
  max-width: 200px;
}

.my-table-crates {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  border-bottom: 1px solid #fff;
}

.my-table-arrows {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100px;
}

.my-table-reactive-button-title {
  margin: 2px 5px;
  border: 1px outset gray;
  border-radius: 5px;
}

.my-table-reactive-button-title:hover {
  margin: 2px 6px;
}

.my-table-header {
  width: 100%;
  padding: 2px 10px 0px 10px;
}

.my-table {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: black;
  overflow-x: auto;
  overflow-y: auto;
  max-height: 100%;
  padding: 4px;
}

.my-table-item {
  margin-top: 10px;
  padding: 4px 10px 2px 10px;
  border-radius: 5px;
  background-color: #f5f5f5;
  color: black;
  width: 100%;
}

.my-table-item:hover {
  background-color: #e5e5e5;
  cursor: pointer;
}

.my-table-item:active {
  background-color: #858282;
  cursor: pointer;
  transform: scale(0.99);
}

.my-table-header,
.my-table-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.my-table-header > div {
  overflow: visible;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.my-table-header > div > div {
  text-decoration: underline;
}

.my-table-header > div > i {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  padding-bottom: 2px;
  text-decoration: none;
}

.my-table-item-large {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.my-table-item-large.multiline {
  flex-wrap: wrap;
}

.my-table-item-imp {
  width: 165px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.my-table-item-sml {
  width: 150px;
}

.my-table-staff-info-title {
  display: flex;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  min-height: 45px;
}

.my-table-action-side {
  width: 75px;
}

.my-table-close-button {
  position: absolute;
  right: 15px;
  border-radius: 50%;
  padding: 2px;
  color: white;
  aspect-ratio: 1;
  width: 40px;
  height: 40px;
}

.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-grow: 1;
  max-height: 100%;
  overflow-y: auto;
}

.page-chooser {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
  width: 100%;
}

.page-chooser-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  height: 50px;
  border: none;
  background-color: #f5f5f5;
}

.page-chooser-button:hover {
  background-color: #d1c630 !important;
}

.page-chooser-button:not(:last-child) {
  border-right: 1px solid #424242;
}

.page-chooser-button.active {
  background-color: #47b83d !important;
}

.pages {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-grow: 1;
  max-height: calc(100% - 50px);
}
