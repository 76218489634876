@import url("https://fonts.googleapis.com/css2?family=Catamaran:wght@100..900&display=swap");

.catamaran-default {
  font-family: "Catamaran", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}

* {
  font-family: "Catamaran", sans-serif;
  font-weight: 450;
}

$color-light-first: #f7f9f7;
$color-light-second: #b6b9b7;
$color-light-third: #7c798b;
$color-light-fourth: #838693;
$color-light-fifth: #313843;
$color-dark-first: #17223b;
$color-dark-second: #263859;
$color-dark-third: #6b778d;
$color-dark-fourth: #ff6768;
$color-dark-fifth: #313843;
$text-primary: #ffffff;
$text-secondary: #000000;
$dark-text-input: #8bd7d2;
$green-contrast: #75c0ab;
:root {
  --color-page-background: #{$color-light-second};
  --color-text: #{$text-primary};
  --color-page-sidenav: #{$color-light-fifth};
  --color-page-header: #{$color-light-fourth};
  --color-page-table: #{$color-light-first};
  --color-text-secondary: #{$text-secondary};
  --input-color: #{$color-light-first};
  --table-head-color: #{$text-secondary};
  --sent-message: #{$color-dark-fourth};
  --recv-message: #{$green-contrast};
}

.dark {
  --color-page-background: #{$color-dark-first};
  --color-text: #{$text-primary};
  --color-page-sidenav: #{$color-dark-fifth};
  --color-page-header: #{$color-light-fourth};
  --color-page-table: #{$color-dark-second};
  --color-text-secondary: #{$text-primary};
  --input-color: #{$dark-text-input};
  --table-head-color: #{$text-primary};
  --sent-message: #{$color-dark-fourth};
  --recv-message: #{$green-contrast};
}

body {
  background-color: var(--color-page-background);
  color: var(--color-text);
  font: bold Tahoma, Geneva, sans-serif;
  font-size: larger;
  font-style: normal;
}

.sidenav {
  background-color: var(--color-page-sidenav);
  -webkit-box-shadow: 3px 6px 13px -4px rgba(0, 0, 0, 0.62);
  -moz-box-shadow: 3px 6px 13px -4px rgba(0, 0, 0, 0.62);
  box-shadow: 3px 6px 13px -4px rgba(0, 0, 0, 0.62);
}

.header {
  -webkit-box-shadow: 3px 6px 13px -4px rgba(0, 0, 0, 0.62);
  -moz-box-shadow: 3px 6px 13px -4px rgba(0, 0, 0, 0.62);
  box-shadow: 3px 6px 13px -4px rgba(0, 0, 0, 0.62);
  background-color: var(--color-page-header);
}

.flex-columns {
  border-radius: 25px;
  background-color: var(--color-page-table);
  -webkit-box-shadow: 3px 6px 13px -4px rgba(0, 0, 0, 0.62);
  -moz-box-shadow: 3px 6px 13px -4px rgba(0, 0, 0, 0.62);
  box-shadow: 3px 6px 13px -4px rgba(0, 0, 0, 0.62);
  color: var(--color-text-secondary);
}

.paginator {
}

tr {
  border-top: var(--color-page-background) solid 1px !important;
  border-bottom: var(--color-page-background) solid 1px !important;
}

input,
textarea,
select {
  background: var(--color-page-table) !important;
  color: var(--color-text-secondary);
  border-color: transparent;
  border-bottom: var(--color-text-secondary) solid 1px;
}

input:focus,
textarea:focus,
select:focus {
  outline: none !important;
}

table {
  color: var(--table-head-color) !important;
  overflow: auto;
}

.recievedMessage {
  background-color: var(--recv-message);
}

.sentMessage {
  background-color: var(--sent-message);
}

.expandable-box-header {
  background-color: var(--color-page-sidenav);
}

.expandable-box-content {
  background-color: var(--color-page-header);
}

.expandable-box {
}

$primary: rgb(247, 151, 51, 0.4);
$primary-selected: rgb(247, 151, 51, 1);
$primary-unselected: rgb(247, 151, 51, 0.2);
.warning {
  background-color: rgb(244, 23, 23);
  color: white;
}

/* ------------------------- Deisgns March 2022 --------------------*/

router-outlet + * {
  width: calc(100% - 8px);
  max-width: calc(100% - 8px) !important;
  height: calc(100% - 20px);
  margin: 10px 4px 10px 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
}

.action-button {
  width: 250px;
  background-color: $primary;
  border-radius: 10px;
  font-size: large;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  color: white;
  padding: 10px 0px 5px 0px;
  margin: 5px 5px;
  transition: all 0.3s ease-in-out;
}

.action-button.selected {
  background-color: $primary-selected;
  color: black;
}

.action-button:disabled {
  background-color: $primary-unselected;
}

.action-button:hover {
  background-color: $primary-selected;
  color: black;
  box-shadow: 1px 1px 5px #000;
}

.page {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  flex-grow: 1;
  max-height: calc(100% - 75px) !important;
  position: relative;
}

@media (max-width: 768px) {
  .action-bar {
    margin: 0px !important;
    width: 100% !important;
  }

  .router-splits {
    font-size: medium;
  }
}

.action-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
  overflow: hidden;
  width: 250px;
  min-height: 300px;
  margin: 10px 0px 10px 16px;
  transition: all 0.3s ease-in-out;
}

.chevron-button {
  width: 50px;
  height: 50px;
  background-color: transparent;
  outline: transparent;
  border: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chevron-button > i {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: xx-large;
  color: white;
}

@media only screen and (max-width: 600px) {
  router-outlet + * {
    width: calc(100% - 4px);
    max-width: calc(100% - 4px) !important;
    height: calc(100% - 4px);
    margin: 2px;
  }
  .page {
    flex-direction: column;
    overflow: auto;
  }
  .page > * {
    width: 100%;
  }
  .page-content {
    margin: 0px 0px 0px 0px !important;
    height: auto !important;
    width: 100% !important;
  }
  .action-bar {
    border-bottom: 1px solid #000;
    padding-bottom: 5px;
    margin-bottom: 5px;
    overflow: auto;
    background-color: #535353;
    border-radius: 0px 0px 15px 15px;
    position: absolute;
    z-index: 25;
  }
  .action-button {
    width: calc(100% - 20px) !important;
  }

  .chevron-button {
    display: flex;
  }
  .page-overview {
  }
  .page-boxes {
    width: 100% !important;
  }
  .page-box-out {
    width: calc(100% - 4px);
    margin: 2px;
  }
}

@media only screen and (min-width: 601px) {
  .page-overview {
    flex-direction: row !important;
  }
  .page-box {
    width: calc(50% - 20px) !important;
    overflow: auto;
  }
  .page-box-sub {
    width: calc(100% - 20px);
    overflow: auto;
  }
  .page-box-out {
    width: calc(50% - 20px);
    min-width: 350px !important;
  }
  .page-content {
    width: calc(100% - 300px);
  }
}

.page-content {
  flex-grow: 1;
  height: calc(100% - 2px);
}

.page-box-out {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: black;
  max-height: calc(100% - 20px);
  overflow: auto;
}

.page-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 3rem;
}

.page-overview {
  background-color: #f79733;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 20px;
  padding: 5px;
  overflow: auto;
  height: calc(100% - 3rem);
  flex-wrap: wrap;
  width: 100% !important;
}

.page-box {
  border-radius: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 5px;
  color: black;
  max-height: calc(100% - 10px);
  overflow: auto;
  margin: 5px;
}

.page-box-sub {
  border-radius: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  padding: 5px;
  color: black;
  overflow: auto;
  margin: 5px;
  width: calc(100% - 20px);
}

.calendar-popup {
  position: absolute;
  z-index: 10;
  background-color: white;
  top: 3rem;
  left: 0px;
  border-radius: 20px;
  border: white solid 5px;
  box-shadow: 1px 1px 5px #000;
  padding: 10px;
  color: black;
}

.flex-even {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.flex-even > * {
  margin: 0 !important;
}

.table-cont {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
}

.darker-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
}

.red {
  background-color: #f83131 !important;
  color: white !important;
}

.redBorder {
  border: 2px solid #ff0f0f !important;
}

.red-i {
  background-color: white;
  color: #ff0f0f;
}

.orange {
  background-color: #ffcc00 !important;
  color: black !important;
}

.gold {
  background-color: #ffcc00 !important;
  color: rgb(250, 250, 250) !important;
  --fa-primary-color: rgb(250, 250, 250) !important;
  --fa-secondary-color: #31312f !important;
}

.silver {
  background-color: #b3b3b3 !important;
  color: rgb(34, 34, 34) !important;
  --fa-primary-color: rgb(82, 81, 81) !important;
  --fa-secondary-color: #31312f !important;
}

.bronze {
  background-color: #cd7f32 !important;
  color: rgb(250, 250, 250) !important;
  --fa-primary-color: rgb(250, 250, 250) !important;
  --fa-secondary-color: #a13502 !important;
}
.orangeBorder {
  border: 2px solid #ffcc00 !important;
}

.green {
  background-color: #339900 !important;
  color: white !important;
}

.greenBorder {
  border: 2px solid #339900 !important;
}

.green-i {
  background-color: white;
  color: #339900;
}

.gray {
  background-color: #b3b3b3 !important;
  color: black !important;
}

.option-button {
  background-color: var(--color-page-header);
  color: var(--color-text);
}

.custom-tooltip {
  white-space: pre-line;
}
