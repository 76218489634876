.modal-title {
  display: flex;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  min-height: 45px;
}

.action-side {
  width: 75px;
}

.close-button {
  position: absolute;
  right: 15px;
  border-radius: 50%;
  padding: 2px;
  color: white;
  aspect-ratio: 1;
  width: 40px;
  height: 40px;
}

.actions {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
}

.actions-footer {
  margin-top: 10px;
}

.actions-header {
  margin-bottom: 10px;
}

.edit-row {
  width: 100%;
  padding: 10px;
}

.edit-row > input {
  width: 100%;
  text-align: center;
}

.edit-row > select {
  width: 100%;
  text-align: center;
}

input:disabled {
  background-color: #f2f2f2;
  opacity: 0.5;
}
